import axios from 'axios';
import { useState, useEffect } from 'react';
import {useNavigate, createSearchParams, useLocation} from 'react-router-dom';
import logo from '../../images/logo.png';
import './Dashboard.css';
import { Login } from "../../pages/login/Login";
import { DashboardListItem } from '../../components/DashboardListItem';
import { DashboardRejectedListItem } from '../../components/DashboardRejectedListItem';
import { DashboardInProgressListItem } from '../../components/DashboardInProgressListItem';

export const Dashboard = () => {
  console.log("In Dashboard page");

  const navigate = useNavigate();
  const token = localStorage.getItem('usertoken');
  const userRole = localStorage.getItem('userrole');
  const [screenId, setScreenID] = useState('');
  const [activeTab, setActiveTab] = useState(1);
  const [refreshAPI, setRefreshAPI] = useState(0);
  const location = useLocation();
  const oldDashboardTab = location.state?.dashboardTab;

  const [pendingArr, setpendingArr] = useState([]);
  const [inprogressArr, setinprogressArr] = useState([]);
  const [completedArr, setcompletedArr] = useState([]);
  const [rejectedArr, setrejectedArr] = useState([]);
  const [clubName, setclubName] = useState('');
  const [isplaying, setisplaying] = useState(false);
  const [errormsg, setErrorMessage] = useState('');
  
  const review = (id) => {
    navigate({
      pathname: '/preview',
      search: createSearchParams({
        id: id
      }).toString()
    });
  }

  const report = (id) => {
    navigate({
      pathname: '/report/'+clubName });

  }

  const updateStatus = (id,status) => {
    if(status === 3){
      endOrderEvent();
    }else if(status === 5){
      beginOrderEvent(id);
    }else{
      const tokenAPI = localStorage.getItem('usertoken');
      let updateStatusApi = process.env.REACT_APP_API_ENDPOINT+"admin/change-status/"+id+"/"+status;
      const headers = {
        'Content-Type': 'application/json',
        'Token': tokenAPI
      }
      axios.put(updateStatusApi, {},{
        headers: headers
      })
      .then(res => {
        if(res.status === 200){
          setRefreshAPI(!refreshAPI);
        }
      })
      .catch((error) => {
        if(error.response.data.msg){
          setErrorMessage(error.response.data.msg);
          showErrorNotification();
        }else if(error.response.status === 401 || error.response.status === 400){
          localStorage.removeItem('usertoken');
          navigate({ pathname: '/admin' });          
        }
      });
    }
  }

  const refundStatus = (id) => {
    const tokenAPI = localStorage.getItem('usertoken');
    let refundStatusApi = process.env.REACT_APP_API_ENDPOINT+"admin/refund-booking";
    const headers = {
      'Content-Type': 'application/json',
      'Token': tokenAPI
    }
    axios.post(refundStatusApi, {booking_id:id},{
      headers: headers
    })
    .then(res => {
      if(res.status === 200){
        setRefreshAPI(!refreshAPI);
      }
    })
    .catch((error) => {
      if(error.response.data.msg){
        setErrorMessage(error.response.data.msg);
        showErrorNotification();
      }else if(error.response.status === 401 || error.response.status === 400){
        localStorage.removeItem('usertoken');
        navigate({ pathname: '/admin' }); 
      }
    });
  }

  function beginOrderEvent(id){
    const tokenAPI = localStorage.getItem('usertoken');
    let startApi = process.env.REACT_APP_API_ENDPOINT+"screen/start-playing";
    const headers = {
      'Content-Type': 'application/json',
      'Token': tokenAPI
    }
    axios.post(startApi, {screen_id:screenId,order_id:id},{
      headers: headers
    })
    .then(res => {
      if(res.status === 200){
        setRefreshAPI(!refreshAPI);
      }
    })
    .catch((error) => {
      if(error.response.data.msg){
        setErrorMessage(error.response.data.msg);
        showErrorNotification();
      }else if(error.response.status === 401 || error.response.status === 400){
        localStorage.removeItem('usertoken');
        navigate({ pathname: '/admin' });
      }
    });
  }

  function endOrderEvent(){
    const tokenAPI = localStorage.getItem('usertoken');
    let endApi = process.env.REACT_APP_API_ENDPOINT+"screen/stop-playing";
    const headers = {
      'Content-Type': 'application/json',
      'Token': tokenAPI
    }
    axios.post(endApi, {screen_id:screenId},{
      headers: headers
    })
    .then(res => {
      if(res.status === 200){
        setRefreshAPI(!refreshAPI);
      }
    })
    .catch((error) => {
      if(error.response.data.msg){
        setErrorMessage(error.response.data.msg);
        showErrorNotification();
      }else if(error.response.status === 401 || error.response.status === 400){
        localStorage.removeItem('usertoken');
        navigate({ pathname: '/admin' });
      }
    });
  } 

  useEffect(() => {
    // manage web socket connections
    notifyRequestedOrder();
    notifyInProgessOrder();
    notifyCompletedOrder();
    notifyRejectedOrder();
    loadLastTabonBack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const tokenAPI = localStorage.getItem('usertoken');
    let dashboardApi = process.env.REACT_APP_API_ENDPOINT+"admin/table-requests/762fa47e-ccb1-4754-9cfe-b4d41b8a9636";
    const headers = {
      'Content-Type': 'application/json',
      'Token': tokenAPI
    }
    axios.get(dashboardApi, {
      headers: headers
    })
    .then(res => {
      if(res.status === 200){
        setclubName(res.data.ClubName);
        const listingDataArr = res.data.Requests;
        if(listingDataArr.length){
          let pendingArrData = [];
          let inprogressArrData = [];
          let completedArrData = [];
          let rejectedArrData = [];
          listingDataArr.forEach(listingItem => {
            if(listingItem.Status === 1){
              pendingArrData.push(listingItem);
            }else if(listingItem.Status === 2){
              inprogressArrData.push(listingItem);
            }else if(listingItem.Status === 3){
              completedArrData.push(listingItem);
            }else if(listingItem.Status === 4 || listingItem.Status === 6){
              rejectedArrData.push(listingItem);
            }else if(listingItem.Status === 5){
              inprogressArrData.push(listingItem);
              setisplaying(true);
            }
          });
          setpendingArr(pendingArrData);
          setinprogressArr(inprogressArrData);
          setcompletedArr(completedArrData);
          setrejectedArr(rejectedArrData);
        }

        // screen ID
        let screenListdApi = process.env.REACT_APP_API_ENDPOINT+"admin/get-screens/"+res.data.ClubID;
        axios.get(screenListdApi, {
          headers: headers
        })
        .then(res => {
          if(res.status === 200){
            if(res.data.club_screens){
              setScreenID(res.data.club_screens[0].screen_id);
            }
          }
        })
        .catch((error) => {
          if(error.response.status === 401 || error.response.status === 400){
            localStorage.removeItem('usertoken');
            navigate({ pathname: '/admin' });
          }
        });
      }
    })
    .catch((error) => {
      if(error.response.status === 401 || error.response.status === 400){
        localStorage.removeItem('usertoken');
        navigate({ pathname: '/admin' });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshAPI]);

  function notifyRequestedOrder(){
    const tokenAPI = localStorage.getItem('usertoken');
    const wsRequested = new WebSocket(
      process.env.REACT_APP_SOCKET_ENDPOINT + "neworders"
    );
    wsRequested.onopen = () => {
      console.log("socket connection for requested orders open");
      wsRequested.send(tokenAPI);
    };

    wsRequested.onclose = () => {
      console.log("socket connection for requested orders closed");
      setTimeout(function() {
        notifyRequestedOrder();
      }, 2000);
    };

    wsRequested.onmessage = (event) => {
      let messageData = JSON.parse(event.data);
      console.log(messageData);
      if(messageData.status === 200){
        if(messageData.msg.Requests){
          console.log("socket connection requested order has data updates");
          setpendingArr(messageData.msg.Requests);
          if(messageData.msg.NewOrder){
            document.getElementById('dashboardNotification').style.display = 'block';
            setTimeout(function(){
              document.getElementById('dashboardNotification').style.display = 'none';
            },3000);
          }
        }
      }
    };
  }

  function notifyInProgessOrder(){
    const tokenAPI = localStorage.getItem('usertoken');
    const wsInProgress = new WebSocket(
      process.env.REACT_APP_SOCKET_ENDPOINT + "inprogressorders"
    );
    wsInProgress.onopen = () => {
      console.log("socket connection for in-progress orders open");
      wsInProgress.send(tokenAPI);
    };

    wsInProgress.onclose = () => {
      console.log("socket connection in-progress orders closed");
      setTimeout(function() {
        notifyInProgessOrder();
      }, 2000);
    };

    wsInProgress.onmessage = (event) => {
      let messageData = JSON.parse(event.data);
      if(messageData.status === 200){
        console.log("socket connection in-progress order has data updates");
        if(messageData.msg.Requests){
          const messageDataArr = messageData.msg.Requests;
          if(messageDataArr.length){
            setisplaying(false);
            messageDataArr.forEach(dataItem => {
              if(dataItem.Status === 5){
                setisplaying(true);
              }
            });
          }
          setinprogressArr(messageData.msg.Requests);
        }
      }
    };
  }

  function notifyCompletedOrder(){
    const tokenAPI = localStorage.getItem('usertoken');
    const wsCompleted = new WebSocket(
      process.env.REACT_APP_SOCKET_ENDPOINT + "completedorders"
    );
    wsCompleted.onopen = () => {
      console.log("socket connection for completed orders open");
      wsCompleted.send(tokenAPI);
    };

    wsCompleted.onclose = () => {
      console.log("socket connection completed orders closed");
      setTimeout(function() {
        notifyCompletedOrder();
      }, 2000);
    };

    wsCompleted.onmessage = (event) => {
      let messageData = JSON.parse(event.data);
      if(messageData.status === 200){
        console.log("socket connection completed order has data updates");
        if(messageData.msg.Requests){
          setcompletedArr(messageData.msg.Requests);
        }        
      }
    };
  }
  
  function notifyRejectedOrder(){
    const tokenAPI = localStorage.getItem('usertoken');
    const wsRejected = new WebSocket(
      process.env.REACT_APP_SOCKET_ENDPOINT + "rejectedorders"
    );
    wsRejected.onopen = () => {
      console.log("socket connection for rejected orders open");
      wsRejected.send(tokenAPI);
    };

    wsRejected.onclose = () => {
      console.log("socket connection rejected orders closed");
      setTimeout(function() {
        notifyRejectedOrder();
      }, 2000);
    };

    wsRejected.onmessage = (event) => {
      let messageData = JSON.parse(event.data);
      if(messageData.status === 200){
        console.log("socket connection rejected order has data updates");
        if(messageData.msg.Requests){
          setrejectedArr(messageData.msg.Requests);
        }
      }
    };
  }

  function showErrorNotification(){
    document.getElementById('dashboardErrorMsg').style.display = 'block';
    setTimeout(function(){
      document.getElementById('dashboardErrorMsg').style.display = 'none';
      setErrorMessage('');
    },3000);
  }

  const loadLastTabonBack = () => {
    if(oldDashboardTab){
      let lastTab = 1;
      if(oldDashboardTab === 5){
        lastTab = 2;
      }else if(oldDashboardTab === 6){
        lastTab = 4;
      }else{
        lastTab = oldDashboardTab;
      }
      setActiveTab(lastTab);
      window.history.replaceState({}, '');
    }
  }

  const tabClick = (tabid) => {
    setActiveTab(tabid);
  }
  
  return (
    <>
      { token ?
      <div className="Admin-page">
        <section className='topSection'>
          <img src={logo} className="Admin-logo-medium" alt="logo" />
          <p>{clubName}</p>
          {
            userRole != 2 &&
            <button id="clientAppReportBtn" className='reportBtn' onClick={report}><span></span>Report</button>
          }
          <div id="dashboardNotification" className="notify">New Order Received</div>
          <div id="dashboardErrorMsg" className="error-notify">{errormsg}</div>
        </section>
        <section className='bottomSection'>
          <ul className="tabsList">
            <li id="clientAppRequestsTab" className={activeTab == 1 ? 'activetab':''} onClick={(e) => tabClick(1)}>
              <div className='tabName'>Requests
              { (pendingArr.length > 0) &&
                <div className='notificationCount'><span>{pendingArr.length}</span></div>
              }
              </div>
            </li>
            <li id="clientAppProgressTab" className={activeTab == 2 ? 'activetab':''} onClick={(e) => tabClick(2)}>
              <div className='tabName'>In Progress
              { (inprogressArr.length > 0) &&
                <div className='notificationCount'><span>{inprogressArr.length}</span></div>
              }
              </div>
            </li>
            <li id="clientAppCompletedTab" className={activeTab == 3 ? 'activetab':''} onClick={(e) => tabClick(3)}>Completed</li>
            <li id="clientAppRejectedTab" className={activeTab == 4 ? 'activetab':''} onClick={(e) => tabClick(4)}>Rejected</li>
          </ul>
          { (clubName != '')?
            <div className="scrollWrap">
              <dl className='dashbord-list'>
                { (activeTab == 1) ?
                  <DashboardListItem name="Requested" btnname="Preview" data={pendingArr} click={review} />
                  : (activeTab == 2) ?            
                  <DashboardInProgressListItem name="In-Progress" data={inprogressArr} playing={isplaying} click={review} update={updateStatus}/>
                  : (activeTab == 3) ?
                  <DashboardListItem name="Completed" btnname="View" icon="checkedRounded" data={completedArr} click={review} redo={updateStatus}/>
                  : (activeTab == 4) ?
                  <DashboardRejectedListItem name="Rejected" icon="crossRounded" data={rejectedArr} click={review} update={updateStatus} refund={refundStatus}/>
                  :
                  <div></div>
                }
              </dl>
            </div>
            :
            <div></div>
          }
        </section>
      </div>
      :
      <Login />
      }
    </>
  );
}