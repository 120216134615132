import {Route, Routes} from 'react-router-dom';
import './App.css';

// Admin
import { Login } from "./Admin/pages/login/Login";
import { Dashboard } from './Admin/pages/dashboard/Dashboard';
import { Preview } from './Admin/pages/preview/Preview';
import { Report } from './Admin/pages/reports/Report';


// App 
import { Signin } from './App/pages/signin/Signin';
import { Scan } from './App/pages/scan/Scan';
import { ConfirmTable } from './App/pages/confirmtable/ConfirmTable';
import { DigitalSign } from './App/pages/digitalsign/DigitalSign';
import { DigitalSignList } from './App/pages/digitalsignlist/DigitalSignList';
import { PreviewSelection } from './App/pages/previewselection/PreviewSelection';
import { UserMedia } from './App/pages/usermedia/UserMedia';
import { Profile } from './App/pages/profile/Profile';
import { Customize } from './App/pages/customize/Customize';
import { OrderStatus } from './App/pages/orderstatus/OrderStatus';
// import { Payment } from './App/pages/payment/Payment';
import { PreviewCustomSelection } from './App/pages/previewcustomselection/PreviewCustomSelection';
import { Screen } from './Admin/pages/screen/Screen';
import { Welcome } from './App/pages/welcome/Welcome';
import { Screenhls } from './Admin/pages/screenhls/Screenhls';
import { Screenhlsandroid } from './Admin/pages/screenhlsandroid/Screenhlsandroid';
// import Stripe from './App/components/Stripe';
import StripePayment from './App/components/StripePayment';
import { ReviewOrder } from './App/pages/revieworder/ReviewOrder';
import { PaymentSuccess } from './App/pages/paymentsuccess/PaymentSuccess';
import { UpdateSuccess } from './App/pages/updatesuccess/UpdateSuccess';

function App() {
  return (
    <Routes>
      {/* Admin */}
      <Route path="/admin" element={<Login />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/preview" element={<Preview />} />
      <Route path="/report/:name" element={<Report />} />
      <Route path="/screen" element={<Screen />} />
      <Route path="/screen-hls" element={<Screenhls />} />
      <Route path="/screen-hls-android" element={<Screenhlsandroid />} />

      {/* App */}
      <Route path="/" element={<Signin />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/scan" element={<Scan />} />
      <Route path="/confirm-table" element={<ConfirmTable />} />
      <Route path="/digital-sign" element={<DigitalSign />} />
      <Route path="/digital-sign-list" element={<DigitalSignList />} />
      <Route path="/preview-selection" element={<PreviewSelection />} />
      <Route path="/preview-custom-selection" element={<PreviewCustomSelection />} />
      <Route path="/user-media" element={<UserMedia />} />
      <Route path="/customize" element={<Customize />} />
      <Route path="/payment" element={<StripePayment />} />
      <Route path="/review-order" element={<ReviewOrder />} />
      <Route path="/order-details/:id" element={<OrderStatus />} />
      <Route path="/payment-success" element={<PaymentSuccess />} />
      <Route path="/update-success" element={<UpdateSuccess />} />
      <Route path="/welcome" element={<Welcome />} />
      <Route path="*" element={<Signin />} />
    </Routes>
  );
}

export default App;
